header {
    text-align: center;
}

a {
    color: rgb(26, 210, 251)
}

.subtext {
    padding-top: 12px;
    padding-bottom: 16px;
}

.page-contents {
    padding: 32px;
}

.profile-header {
    padding-left: 32px;
    padding-right: 32px;
}

.name-text {
    padding-bottom: 24px;
}

.profile-name {
    padding-top: 32px;
}

.profile-toggle {
    margin-left: auto;
    padding-left: 8px;
}

.toggle-line {
    display: flex;
    align-items: top;
    padding-top: 16px;
}

.primary-button {
    display: block;
    width: 100%;
    padding-top: 32px;
}

.hidden {
    display: none;
}

.loading-spinner-wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

@import 'Modal';
@import 'Share';
@import 'ShareProfileImageCollage';
@import 'PlaylistVisibility';
