.background-blur {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.96);
    @supports (backdrop-filter: blur(32px)) {
        background-color: rgba(0, 0, 0, 0.6);
        backdrop-filter: blur(32px);
    }
}

.fullscreen-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
}

.modal-contents {
    position: relative;
    padding: 32px;
    top: 50%;
    transform: translateY(-50%);
}

.modal-close-container {
    overflow: auto;
}

.modal-close {
    position: relative;
    float: right;
    padding-bottom: 8px;
}

.modal-header {
    padding-bottom: 24px;
}

.modal-body {
    padding-bottom: 24px;
}

.modal-button {
        min-width: 160px;
}

.modal-button-text {
    font-size: 18px;
}
