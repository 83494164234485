#share-profile {
    position: relative;
    height: 100vh;
    .buttons {
        position: absolute;
        bottom: 32px;
        left: 32px;
        right: 32px;
    }
    .primary-button {
        padding-top: 16px;
    }
}
