.profile-collage {
    position: relative;
    .profile-image {
        padding-top: 16px;
    }
    .playlist-image {
        position: absolute;
        &.first {
            top:0;
            left: calc(50% - 112px);
            width: 80px;
            height: 80px;
        }
        &.second {
            top: calc(50% - 84px);
            left: calc(50% + 52px);
            width: 100px;
            height: 100px;
        }
    }
}
